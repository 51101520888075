<template>
  <div class="wrapper-box">
    <div class="vd_head_button" v-if="isShowBTN">
      <el-button @click="subAppr()" type="success" :disabled="btnAble" size="small">提交审批</el-button>
    </div>
    <div class="vd_head_button" v-if="!isShowBTN && !isEffect ">
      <el-button size="small" type="success" @click="passAppr()" :disabled="btnAble">通过</el-button>
      <el-button size="small" type="danger" @click="redAppr()" :disabled="btnAble">拒绝</el-button>
    </div>
    <div class="vd_head_button" v-if="isEffect">
      <div class="vd_head_button" v-if="isShowEffect">
        <el-button size="small" type="warning" @click="effectAppr()">生效</el-button>
      </div>
      <div class="vd_head_button" v-if="!isShowEffect">
        <div class="vd_head_button" v-if="isIneffective">
          <el-button size="small" type="danger" @click="ineffectiveAppr()">反生效</el-button>
        </div>
        <div v-if="!isIneffective">
          此单据已生效
        </div>
      </div>
    </div>
    <div class="vd_select_plane" v-if="showPanle">
      <el-card class="vd_textarea" shadow="none">
        <span> ({{this.btnCont}})备注：</span>
        <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="textarea"></el-input>
      </el-card>
      <el-card shadow="none">
        <div v-if="isSelPanel">
          <span>选择审批人：</span>
          <el-checkbox-group v-model="checkStffIds">
            <el-checkbox v-for="item in checkList" :label="item.user_id" :key="item.user_id">{{item.stff_name}}  {{item.dept_name}}</el-checkbox>
          </el-checkbox-group>
        </div>
        <div v-if="!isSelPanel">
          <i class="el-icon-warning"></i>
          <span class="vd_msg">{{this.msgContent}}</span>
        </div>
        <div class="vg_button">
          <el-button size="small" @click="closePanel()">取消</el-button>
          <el-button size="small" type="primary" @click="submitPa(checkStffIds)">确认</el-button>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import {get,post} from "@api/request";
import {apprAPI} from "@api/modules/appr";

export default {
  name: "apprNext",
  props:{
    apprNext:Object,
    isShowBTN:Boolean,
    isEffect:Boolean,
    isIneffective:Boolean,
    isShowEffect:Boolean,
    nextContect:String,
    isShow:Boolean,
  },
  data(){
    return{
      checkStffIds:[],
      stffNameList:[],
      textarea:'',
      showPanle: false,
      btnAble: false,
      btnCont:'',
      apprFormB:{}, // 提交获取的信息
      apprForm:{},
      checkList:[],
      msgContent: '',
      isSelPanel: true,
    }
  },
  created() {

  },
  mounted() {
  },
  methods:{
    // 提交
    subAppr(){
      this.showPanle = true
      this.btnAble = true
      this.apprForm = this.apprNext
      this.apprForm.appr_inst_this_node = -1
      this.apprForm.appr_inst_action = 1
      this.btnCont = '提交'
      this.getStffList()
    },
    // 通过
    passAppr(){
      this.showPanle = true
      this.btnAble = true
      this.apprForm = this.apprNext
      this.apprForm.appr_inst_action = 2
      this.btnCont = '通过'
      this.getStffList()
    },
    // 拒绝
    redAppr(){
      this.showPanle = true
      this.btnAble = true
      this.apprForm = this.apprNext
      this.btnCont = '拒绝'
      this.apprForm.appr_inst_action = 3
      this.getStffList()
    },
    // 生效按钮
    effectAppr(){
      if(this.isShow){
        this.$confirm('请确定是否生效?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        .then(() => {
          this.effect()
        })
        .catch(()=>{
          this.$message({
            type:'info',
            message:'已取消生效'
          })
        })
      }else{
        this.$message({
          type:'warning',
          message:'单据信息编辑未完成'
        })
      }
    },
    // 反生效按钮
    ineffectiveAppr(){
      if(this.isShow){
        this.$confirm('请确定是否反生效?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        .then(() => {
          this.ineffective();
        })
        .catch(()=>{
          this.$message({
            type:'info',
            message:'已取消反生效'
          })
        })
      }else{
        this.$message({
          type:'warning',
          message:'单据信息编辑未完成'
        })
      }
    },
    // 获取审批人
    getStffList(){
      const nodeBody = {}
      nodeBody.perm_id = this.apprForm.perm_id
      nodeBody.form_id = this.apprForm.form_id
      nodeBody.appr_inst_this_node = this.apprForm.appr_inst_this_node
      nodeBody.appr_inst_action = this.apprForm.appr_inst_action
      nodeBody.appr_id = this.apprNext.appr_id
      get(apprAPI.getApprInstNextNode,nodeBody)
       .then(res=>{
         if(res.data.code === 0){
           this.apprFormB = res.data.data
           this.checkList = this.apprFormB.appr_node_user_list
           if(this.apprFormB.appr_node_id === -1){
             this.isSelPanel = false
             this.msgContent = '确认后单据将退回到草拟状态!'
           } else if(this.apprFormB.appr_node_id === 0){
             this.isSelPanel = false
             this.msgContent = '确认后单据将变成为生效状态!'
           }
         } else {
           let mg = res.data.msg;
           this.isSelPanel = false
           this.msgContent = mg
         }
       })
    },
    // 关闭面板
    closePanel(){
      this.btnAble = false
      this.showPanle = false
    },
    // 提交选择人
    submitPa(checkStffIds){
      if(this.isSelPanel){
        if(checkStffIds.length === 0){
          this.$message({type:'warning',message:'请至少勾选一个审核人员！'})
        } else {
          this.saveInfo()
        }
      } else {
        this.saveInfo()
      }
    },
    // 保存选择人挂api
    saveInfo(){
      this.apprForm.appr_inst_remark = this.textarea
      this.apprForm.next_user_id_list = this.checkStffIds
      this.apprForm.appr_inst_next_node = this.apprFormB.appr_node_id
      post(apprAPI.Approve,this.apprForm)
      .then(res=>{
        if(res.data.code === 0){
          this.checkStffIds = []
          this.$emit('getApprObj')
          this.btnAble = false
          this.showPanle = false
        } else {
          let msg = res.data.msg;
          let q = 'error';
          this.$message({type:q,message: msg})
        }
      })
    },
    // 直接生效
    effect(){
      const nodeBody = {}
      nodeBody.perm_id = this.$route.query.perm_id
      nodeBody.form_id =this.$route.query.form_id
      post(apprAPI.makeEffective,nodeBody)
      .then(res=>{
        if(res.data.code === 0){
          this.$emit('getApprObj')
          this.$emit('flag',this.flag)
          this.btnAble = false
          this.showPanle = false
        }else{
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message: mg, type: tp});
        }
      })
      .catch(res => {
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message: mg, type: tp});
      })
    },
    // 反生效
    ineffective(){
      const nodeBody = {}
      nodeBody.perm_id = this.$route.query.perm_id
      nodeBody.form_id =this.$route.query.form_id
      post(apprAPI.makeIneffective,nodeBody)
      .then(res=>{
        if(res.data.code === 0){
          this.$emit('showEffect',true)
          this.$emit('getApprObj')
          this.btnAble = false
          this.showPanle = false
          this.isSelPanel = true
        }else{
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message: mg, type: tp});
        }
      })
      .catch(res => {
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message: mg, type: tp});
      })
    },
  }
}
</script>

<style scoped lang="scss">
//.wrapper ::v-deep .el-card{
//  color:#969696
//}
.wrapper-box{
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
}
.vd_textarea{
  margin-bottom: 5px;
  .el-textarea{
    margin-top: 5px;
  }
}
.vd_select_plane{
  height: 100%;
  margin-top: 5px;
  max-width: 400px;
  min-height: 200px;
  position:relative;
  animation:mymove 0.2s forwards;
  animation-delay:0.2s;
  .el-checkbox-group{
    margin-top: 5px;
  }
}
@keyframes mymove
{
  from {top:0px;}
  to {top:20px;}
}

.vg_button {
  display: flex;
  justify-content: flex-end;
}
.vd_button {
  display: flex;
  justify-content: right;
}
.el-icon-warning{
  color: $color-deep-red;
}
.vd_msg {
  margin-left: 5px;
  font-weight: 700;
}

</style>