<template>
  <div class="vg_wrapper">
    <el-card>
      <el-upload
          :disabled="!btn.add"
          ref="upload"
          class="upload-demo"
          :action="uploadUrl"
          :data="uploadData"
          :on-preview="handlePreview"
          multiple
          :before-upload="beforeUpload"
          :on-success="handleSuccess">
        <el-button size="small" type="primary" :disabled="!btn.add">上传</el-button>
        <div slot="tip" class="el-upload__tip">文件大小不得超过50MB</div>
      </el-upload>
      <el-row>
        <el-table ref="docuList" :data="tableData" border>
          <el-table-column label="序号" width="48" align="center">
            <template slot-scope="scope">
              {{ scope.$index+1 }}
            </template>
          </el-table-column>
          <el-table-column label="文件名" prop="docu_name" />
          <el-table-column label="格式" prop="docu_suffix" />
          <el-table-column  label="操作" align="center" width="300">
            <template slot-scope="scope">
              <el-row type="flex" justify="center">
                <el-link type="success" class="vg_cursor vd_mar10" @click="download(scope.row)">下载</el-link>
                <el-link type="danger" v-if="specialDataFlag === 1 || specialDataFlag===scope.row.acct_id" class="vg_cursor vd_mar10" @click="remove(scope.$index, scope.row)">删除</el-link>
                <el-link type="warning" class="vg_cursor vd_mar10" @click="seeShow(scope.row)" v-if="imgarr.indexOf(scope.row.docu_suffix)!=-1">查看图片</el-link>
                <el-link type="warning" class="vg_cursor vd_mar10" @click="openVideo(scope.row)" v-if="videoarr.indexOf(scope.row.docu_suffix)!=-1">预览视频</el-link>
              </el-row>
            </template>
          </el-table-column>
        </el-table>
        <el-dialog
          title="图片查看"
          :visible.sync="dialogVisible"
          width="50%"
          class="showImg"
        >
          <div class="vd_center">
            <el-image
            class="showImg"
            :src="showImgUrl"
            fit="contain"></el-image>
          </div>
        </el-dialog>
        <el-dialog
          :before-close="handleClose"
          :visible.sync="dialogVisible1"
          width="60%"
        >
          <vue-core-video-player
            style="width:90%;"
            ref="vueVideo"
            :src="videoUrl"
          ></vue-core-video-player>
        </el-dialog>
      </el-row>
    </el-card>
  </div>
</template>
<script src="./dist/vue-core-vide-player.umd.min.js"></script> 
<script>
import {get,post} from "@api/request";
import {docuAPI} from "@api/modules/docu";
import helper from "@assets/js/helper";

export default {
  name: "docuAbout",
  props:{
    docuAbout:Object,
    // isShow:{
    //   type:Boolean,
    //   required:true,
    // },
  },
  data(){
    return{
      uploadData:{
        perm_id:'',
        form_id:'',
      },
      tableData:[],
      // fileList:[],
      uploadUrl: helper.modePrepend(docuAPI.uploadDocuments),
      docuUrl:'',
      docuName:'',
      btn:{},
      specialDataFlag:false,
      dialogVisible:false,
      dialogVisible1:false,
      showImgUrl:'',
      videoUrl:'',
      imgarr:['bmp','jpg','png','tif','gif','pcx','tga','exif','fpx','svg','psd','cdr','pcd','dxf','ufo','eps','ai','raw','WMF','webp','avif','apng','jpeg' ],
      videoarr: ['mp4','ogg','flv','avi','wmv','mwv','rmvb']
    }
  },
  created() {
    this.initData()
  },
  methods: {
    initData(){
      this.getDocus();
      this.specialDataFlag = this.$cookies.get('userInfo').acct_id
    },
    // buttonClick(){
    //   if(this.isShow){
    //     this.$confirm('请确定是否反生效?', '提示', {
    //       confirmButtonText: '确定',
    //       cancelButtonText: '取消',
    //       type: 'warning'
    //     })
    //     this.beforeUpload()
    //   }else{
    //     this.$message({
    //       type:'warning',
    //       message:'单据信息编辑未完成'
    //     })
        
    //   }
    //   console.log('5555',this.isShow)
    // },
    //上传
    handlePreview(file) {
    },
    // handleRemove(file, fileList) {
    //   console.log(file, fileList);
    // },
    // handleExceed(){
    //   this.$message.error('只能上传一个文件')
    // },
    beforeUpload(file){
      let fileName = file.name.substr(0,file.name.lastIndexOf('.'))
      if(fileName.length>50){ 
        this.$message.warning('文件名字过长! 长度不可大于50!')
        return  false  
      } 
      if(file.name === ' ' || file.name === null){
        this.$message.warning('文件名字不能为空')
        return false
      }
      if(file.size === 0){
        this.$message.warning('不能上传空文件')
        return false
      }
      const isLt5M = file.size /1024/1024 < 50
      this.uploadData.perm_id = this.$route.query.perm_id
      this.uploadData.form_id = this.$route.query.form_id
      if (!isLt5M) {
        this.$message.error('文件大小不得超过 50MB!');
      }
      return isLt5M; 
    },
    handleSuccess(val){
      if(val.code === 2){
        this.$message({
          type:'error',
          message:'上传文件不能为空文件！'
        })
      }
      setTimeout(()=>{
        this.$refs.upload.clearFiles(); //去掉文件列表
      },400)
      this.initData()
    },
    //获取相关文档列表
    getDocus(){
      get(docuAPI.getDocus,{perm_id:this.$route.query.perm_id,form_id: this.$route.query.form_id})
          .then(res => {
            if(res.data.code === 0){
              this.tableData = res.data.data.list;
              this.btn = res.data.data.btn;
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message:mg,type:tp});
            }
          })
          .catch(res=>{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
          })
    },
    //下载
    download(row){
      this.docuUrl = this.helper.megPath(row.docu_url,row.docu_pter_source)
      // http://localhost:8885/file/document/20211216/1639639858419.jpg
      this.docuName = row.docu_name+'.'+row.docu_suffix
      this.helper.downloadItem(this.docuUrl,this.docuName,row.docu_pter_source)
    },
    //视频暂停
    handleClose() {
      this.$refs.vueVideo.pause();
      this.dialogVisible1 = false;
    },
    openVideo(row) {
      this.dialogVisible1 = true;
      if (!this.$refs.vueVideo) {
        this.videoUrl = helper.pVideoUrl(row.docu_url,row.docu_pter_source)
      }else{
         this.$refs.vueVideo.play();
      }
    },
    // 查看图片
    seeShow(row){
      let updateTime = JSON.parse(JSON.stringify(new Date().getTime()))
      this.dialogVisible = true
      this.showImgUrl = helper.picUrlDoc(row.docu_url, 'l',updateTime,row.docu_pter_source)
    },
    //单行删除
    remove(index, row) {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        post(docuAPI.destroyDocuPterById, {docu_pter_id: row.docu_pter_id})
            .then(res => {
              if (res.data.code === 0) {
                this.$message({message: '成功', type: 'success'});
                this.initData()
              } else {
                let mg = res.data.msg;
                let tp = 'error';
                this.$message({message: mg, type: tp});
                this.initData()
              }
            }).catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message: mg, type: tp});
        })
      })
      .catch(()=>{})
    },
  }
}
</script>

<style scoped lang="scss">
.upload-demo ::v-deep .el-upload--text {
  width: auto;
  height: auto;
  text-align: left;
  border: none;
  border-radius: 0;
}
.el-upload__tip{
  margin-bottom: 16px;
}
.vd_mar10{
  margin-right: 15px;
}
.vd_center{
  display: flex;
  justify-content: center;
}
</style>