<template>
  <div class="wrapper" >
    <el-row :gutter="20">
      <el-col :md="8">
        <el-card>
          <appr-next
              v-loading="loadingFlag"
              :apprNext="apprNext"
              :isShowBTN="isShowBTN"
              :isEffect="isEffect"
              :isShow="isShow"
              :isShowEffect="isShowEffect"
              :isIneffective="isIneffective"
              @getApprObj="getApprObj"
              @showEffect="showEffect"
              v-if="apprNext"></appr-next>
              
          <div v-else>
            <i class="el-icon-info"></i>
            <span class="vg_ml_8">暂无审批</span>
          </div>
        </el-card>
      </el-col>
      <el-col :md="16">
        <appr-list :apprList="apprList"></appr-list>
      </el-col>
    </el-row>
    
  </div>
</template>

<script>
import {get} from "@api/request";
import {apprAPI} from "@/api/modules/appr"
import apprNext from "@/views/component/apprCommon/apprNext";
import apprList from "@/views/component/apprCommon/apprList";

export default {
  name: "apprMain",
  components: {
    apprList,
    apprNext
  },
  props:{
    isShow:{
      type:Boolean,
      required:true,
    },
  },
  data(){
    return{
      apprList:[],
      apprNext:{},
      apprNextFlag:false,
      nextContect:'',
      isShowBTN:false,
      isEffect:false,
      isShowEffect: false,
      isIneffective:false,
      loadingFlag:true
    }
  },
  created() {
    this.initData()
  },
  methods:{
    initData(){
      this.loadingFlag = true
      this.getApprObj()
    },
    // 反生效清除按钮
    showEffect(val){
      this.isShowEffect = false;
      this.isEffect = false
      this.$nextTick(()=>{
        this.isShowEffect = val;
      })
    },
    // 获取所有信息
    getApprObj(){
      get(apprAPI.getApprInsts,{perm_id:this.$route.query.perm_id,form_id:this.$route.query.form_id,acct_id:null})
      .then(res=>{
        if(res.data.code === 0){
          this.apprList = res.data.data.list
          this.apprNext = res.data.data.next
          this.apprNextFlag = true
          if(this.apprNext){
            if(this.apprNext.appr_inst_this_node === -1){
              this.isShowBTN = true
            } else if (this.apprNext.appr_inst_this_node === 0) {
              this.isShowBTN = false
              this.isEffect = true
            } else {
              this.isShowBTN = false
            }
          } else {
            this.nextContect = '暂无审批'
          }
        } else if (res.data.code === 2) {
          this.apprNextFlag = false
          this.isEffect = true
          if(res.data.data.status === 0){
            this.isShowEffect = true
          } else if (res.data.data.status === 2){
            this.isShowEffect = false
            if(res.data.data.btn === true){
              this.isIneffective = true
            }else{
              this.isIneffective = false
            }
          }
        }
        setTimeout(() => {
          this.loadingFlag = false
        }, 500);
      })
      .catch(()=>{})
      this.$emit('draftState')
    },
  }
}
</script>

<style scoped lang="scss">
 .wrapper .el-row{
   padding: 20px 80px;
 }
 .el-icon-info{
   color: $color-info;
 }
</style>