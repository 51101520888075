<template>
 <div>
   <el-card shadow="always" v-for="(item,index) in apprList" :key="index" class="vg_mb_16">
     <div slot="header" class="clearfix">
       <el-row type="flex" justify="space-between">
         <el-col :md="6">
           <span class="vd_CH vd_CHL">审批节点：</span><span class="vd_CH">{{item.appr_node_name}}</span>
         </el-col>
         <el-col :md="6" class="vg_pagination-container">
           <span class="vd_CH vd_CHL">审批状态：</span>
           <el-tag :type="helper.getInstNextNode(item.appr_inst_next_node).type" size="mini">{{helper.getInstNextNode(item.appr_inst_next_node).name}}</el-tag>
         </el-col>
       </el-row>
     </div>
     <div>
       <el-form size="mini" :label-position="labelPosition">
         <el-row type="flex" justify="space-between">
           <el-col :md="6">
             <el-form-item label="审批人：" prop="stff_name">
               {{item.stff_name }}
             </el-form-item>
           </el-col>
           <el-col :md="12">
             <el-form-item label="审批时间：" prop="update_time" class="vg_pagination-container">
               {{helper.toTime(item.update_time)}}
             </el-form-item>
           </el-col>
         </el-row>
         <el-row class="vg_mt_16">
           <el-col>
             <el-form-item label="备注：" label-width="55px" prop="appr_inst_remark">
               <el-input type="textarea" :rows="1" size="small" v-model="item.appr_inst_remark" disabled></el-input>
             </el-form-item>
           </el-col>
         </el-row>
         <el-row class="vg_mt_16" type="flex" justify="space-between">
           <el-col :md="12">
             <el-form-item label="发起人说明：" prop="appr_inst_info">
               {{item.appr_inst_info}}
             </el-form-item>
           </el-col>
           <el-col :md="6">
             <el-form-item label="审批结果：" class="vg_pagination-container">
               <el-link :underline="false" :type="helper.getInstAction(item.appr_inst_action).type">{{helper.getInstAction(item.appr_inst_action).name}}</el-link>
             </el-form-item>
           </el-col>
         </el-row>
       </el-form>
     </div>
   </el-card>
 </div>
</template>

<script>
export default {
  name: "apprList",
  components: {
  },
  props:{
    apprList:Array
  },
  data(){
    return{
      labelPosition:'left'
    }
  },
  methods:{
  }
}
</script>

<style scoped lang="scss">
.vd_CH{
  font-size: 12px;
}
.vd_CHL{
  color: $color-info;
}
.el-card{
  ::v-deep.el-form-item__label{
    color: $color-info;
  }
  ::v-deep.el-card__header{
    padding: 12px 20px;
  }
}
</style>